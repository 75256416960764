import React, { Component } from 'react';
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import { connect } from 'react-redux';
import { withStyles } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import moment from 'moment';
import api from '../api';
import AddWorkDay from '../components/AddWorkDays';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ConfirmDialog from '../components/ConfirmDialog';
import { Dialog, DialogContent, Button } from '@mui/material';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 4),
    },    position: "relative",
  },
  deleteWorkingContainer: {
    display: "flex",
    justifyContent: "right",
    "& svg": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    "& svg:hover": {
      cursor: "pointer",
      color: theme.palette.primary.action.hover,
    }
  },
  working: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    padding: theme.spacing(2, 2),
    border: "1px solid #582C83",
    borderRadius: "5px",
    [theme.breakpoints.down('xs')]: {
      textAlign: "center",
    },
  },
  workingContent: {
    flex: '1'
  },
  workingData: {
    fontSize: "1.2rem",
    fontWeight: "500",
  },
  pastWorking: {
    "& > div": {
    border: "1px solid #999999",
    color: "#999999",
    "& svg": {
      cursor: "not-allowed",
      color: "#999999",
    },
    "& svg:hover": {
      cursor: "not-allowed",
      color: "#999999",
    }
  },
  },
});

class Working extends Component {
  state = {
    workDays: [],
    openDelete: false,
    addDaysDialog: false,
    deleteId: null,
    deleteWorkDayTitle: ''
  }

  componentDidMount() {
    this.updateMyWorkDays();
  }

  createWorkDay = ({ start, end }) => {
    api.working.create({
      from: start,
      to: end,
      token: this.props.auth.jwt
    }).then(() => {
      this.updateMyWorkDays();
    })
  }

  updateWorkDays = ({ dates }) => {
    const normalizedDates = dates.map(d => d.format());
    api.working.create({
      dates: normalizedDates,
      token: this.props.auth.jwt
    }).then(() => {
      this.updateMyWorkDays();
      this.closeAddDaysDialog()
    })
  }

  updateMyWorkDays = () => {
    api.working.mine({ token: this.props.auth.jwt }).then(res => {
      this.setState({ workDays: [...res.data.working] })
    })
  }

  openDeleteWorkDay = (id) => {
    const workDays = [...this.state.workDays];
    const index = workDays.findIndex(e => e._id === id);
    if (index === -1) return;
    const workDay = workDays[index];
    const text = `Are you sure you no longer want to work at Oxford on ${moment(workDay.date).format("ddd Do MMM, YYYY")}`

    this.setState({ openDelete: true, deleteId: id, deleteWorkDayTitle: text });
  }

  closeDeleteDialog = () => {
    this.setState({ openDelete: false, deleteId: null });
  }

  closeAddDaysDialog = () => {
    this.setState({ addDaysDialog: false });
  }

  confirmDelete = () => {
    const workDays = [...this.state.workDays];
    const workDayId = this.state.deleteId;
    const index = workDays.findIndex(e => e._id === workDayId);
    if (index === -1) return;

    api.working.delete({ id: workDayId, token: this.props.auth.jwt }).then(res => {
      workDays.splice(index, 1);
      this.setState({ workDays })
    }).catch(err => console.log(err))

    this.closeDeleteDialog();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <PageTitle>My Work Days</PageTitle>
        <Paper elevation={0} square className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
              <AddWorkDay dates={this.state.workDays.map(d => d.date)} onSubmit={this.updateWorkDays} />
              {/* <Button onClick={() => this.setState({ addDaysDialog: true })} variant="contained" color="primary">Add Work Days</Button> */}
            </Grid>
            {this.state.workDays.map(work => (
              <Grid item xs={12} key={work._id} className={moment(work.date).isBefore(moment()) ? classes.pastWorking : ""}>
                <div className={classes.working}>
                  <div className={classes.deleteWorkingContainer}>
                    <DeleteIcon onClick={() => moment(work.date).isBefore(moment()) ? {} : this.openDeleteWorkDay(work._id)} />
                  </div>
                  <div className={classes.workingContent}>
                  Working onsite
                  <br />
                  <div className={classes.workingData}>
                    {moment(work.date).format("ddd Do MMM, YYYY")}
                  </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Paper>
        <Dialog onClose={this.closeAddDaysDialog} open={this.state.addDaysDialog} maxWidth="md">
          <DialogContent>
            <AddWorkDay dates={this.state.workDays.map(d => d.date)} onSubmit={this.updateWorkDays} />
          </DialogContent>
        </Dialog>
        <ConfirmDialog 
          open={this.state.openDelete} 
          cancel={this.closeDeleteDialog} 
          confirm={this.confirmDelete} 
          title={this.state.deleteWorkDayTitle} 
          text=""
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Working));