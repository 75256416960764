import React, { Component } from 'react';
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import Carousel from 'react-material-ui-carousel'
import { connect } from 'react-redux';
import { withStyles } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import MainImage from '../assets/about/malthouse.jpg';
import KitchenImage from '../assets/about/Kitchen.jpg';
import CorridorImage from '../assets/about/Corridor.jpg';
import DiningImage from '../assets/about/Dining-room.jpg';

import FrontLImage from '../assets/about/landscape/Front-of-the-Malthouse.jpg';
import KitchenLImage from '../assets/about/landscape/Kitchen-1.jpg';
import DiningLImage from '../assets/about/landscape/Dining-room.jpg';
import LoungeLImage from '../assets/about/landscape/Living-Room.jpg';

import Side1Image from '../assets/about/side1.jpg';
import Side2Image from '../assets/about/side2.jpg';
import Side3Image from '../assets/about/side3.jpg';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 4),
    },    
    position: "relative",
    marginBottom: '2rem'
  },
  roomContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #bbb',
    borderRadius: '5px',
    position: 'relative',
    overflow: 'hidden',
    height: '100%'
  },
  image: {
    width: '100%',
    borderRadius: '5px 5px 0px 0px',
    // margin: theme.spacing(0, 0, 2, 0)
  },
  infoArea: {
    padding: theme.spacing(3),
    flex: 1
  },
  header: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  info: {
    display: 'block',
    padding: theme.spacing(0.5),
  },
  facilitiesContainer: {
    margin: theme.spacing(2, 0, 1, 0),
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  facilities: {
    border: '1px solid #582C83',
    color: '#582C83',
    borderRadius: '5px',
    padding: '3px 6px',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '5px',
  },
  textBox: {
    fontSize: '1rem'
  },
  mainImage: {
    width: '100%'
  }
});

class About extends Component {

  render() {
    const { classes } = this.props;
    return (
      <div>
        <PageTitle>About</PageTitle>
        <Paper elevation={0} square className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img src={MainImage} alt="The Malthouse" className={classes.mainImage} />
            </Grid>
            <Grid item xs={12} className={classes.textBox}>
              <p>The Malthouse and the Granary are the offices for Purple Transformation Group.  A place to work together and build a special business.<br/>
              If you've got here you're the reason why that will happen.<br/>
              This is just the beginning.........</p>
              <p>The Malthouse is a Grade 2 listed building dating from the early 18th century, and features original timber, brick and stonework. It is a large, comfortable four-bedroom home that can sleep eight adults and one child in a day bed in one of the bedrooms.</p>
              <p>Part of the Kingston Bagpuize Estate, the Malthouse was converted into a home 40 years ago and has recently been completely refurbished. The house is situated in the Courtyard, as part of the main estate.  It has been featured in various productions for film and television, including Downton Abbey and Emma (2020).</p>
              <p>The house has been lovingly decorated in an elegant and understated style, with an eclectic mix of antiques and modern furnishings.</p>
              <p>The kitchen is fully equipped with a large fridge-freezer, stove, and dishwasher and has a door leading out onto a patio complete with gas-fuelled BBQ. The living room has a Smart TV, large wood burning stove, and French windows leading outside into the garden.  Between the entrance hall and the living room is the dining room, with a large table perfect for special occasions.</p>
              <p>The private enclosed garden behind the house provides a quiet and secluded place to sit and relax, and has gated access directly to the West Park, which you will be free to roam during your stay.</p>
            </Grid>
            <Grid item xs={6}>
              <img src={FrontLImage} alt="The Malthouse Kitchen" className={classes.mainImage} />
            </Grid>
            <Grid item xs={6}>
              <img src={LoungeLImage} alt="The Malthouse Corridor" className={classes.mainImage} />
            </Grid>
            <Grid item xs={6}>
              <img src={KitchenLImage} alt="The Malthouse Dining Room" className={classes.mainImage} />
            </Grid>
            <Grid item xs={6}>
              <img src={DiningLImage} alt="The Malthouse Dining Room" className={classes.mainImage} />
            </Grid>
          </Grid>
        </Paper>
        <PageTitle>Keylock Location</PageTitle>
        <Paper elevation={0} square className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img src={Side1Image} alt="The Malthouse Side" className={classes.mainImage} />
            </Grid>
            <Grid item xs={4}>
              <img src={Side2Image} alt="The Malthouse Side" className={classes.mainImage} />
            </Grid>
            <Grid item xs={4}>
              <img src={Side3Image} alt="The Malthouse Side" className={classes.mainImage} />
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(About));
