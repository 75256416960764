import React from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/material/styles';

const styles = theme => ({
  title: {
    fontWeight: 500,
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1rem'
    }
  }
});

const PageTitle = ({children, classes}) => {
  return (
    <Typography variant="h5" component="h1" className={classes.title} gutterBottom>
      {children}
    </Typography>
  );
};

export default withStyles(styles, { withTheme: true })(PageTitle);