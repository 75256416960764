import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Authenticated from './layouts/Authenticated';
import Authentication from './layouts/Authentication';
import Dashboard from './layouts/Dashboard';

const App = () => {
  return (
    <Switch>
      <Route path={[ "/login", "/forgotten-password", "/reset-password" ]} component={Authentication} />
      <Route path={[ "/create-password", ]} component={Authenticated} />
      <Route path="/" component={Dashboard} />
    </Switch>
  );
};

export default App;