import React, { Component } from 'react';
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { connect } from 'react-redux';
import { withStyles } from "@mui/material/styles";
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
import rooms from '../config/rooms';
import AddIcon from '@material-ui/icons/Add';
import api from '../api';
import { isAdmin } from '../config/authorization';
import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, Switch, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Toolbar, Tooltip } from '@mui/material';
import CreateUserDialog from '../components/CreateUserDialog';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 4),
    },
    position: "relative",
  },
  toolbar: {
    justifyContent: 'flex-end'
  },
  tableContainer: {
    display: 'block',
    overflow: 'auto',
  },
});

class Users extends Component {
  state = {
    users: [],
    userCount: 23,
    page: 0,
    createUserDialog: false,
    newUser: {},
    createUserLoading: false,
    createUserError: false
  }

  componentDidMount() {
    this.getUsers({page: 0})
  }

  onChange = e => {
    this.setState({ newUser: {...this.state.newUser, [e.target.name]: e.target.value}, createUserError: false });
  };

  getUsers = ({ page }) => {
    api.admin.users.get({ token: this.props.auth.jwt, page })
    .then(res => {
      this.setState({ users: [...res.data.users], userCount: res.data.count, page })
    }).catch(err => {
      console.log(err);
    })
  }

  openCreateUser = () => {
    this.setState({ createUserDialog: true });
  }

  closeNewUser = () => {
    this.setState({ createUserDialog: false, newUser: {}, createUserLoading: false, createUserError: false })
  }

  createUserSubmit = (e) => {
    e.preventDefault();
    this.setState({ createUserLoading: true });
    api.admin.users.create({
      token: this.props.auth.jwt,
      ...this.state.newUser
    }).then(() => {
      this.closeNewUser();
      this.getUsers({ page: this.state.page });
    }).catch(err => {
      this.setState({createUserError: err.response.data.msg, createUserLoading: false})
    });
  }

  toggleUserRole = async (email) => {
    if (this.props.auth.user.email === email) {
      return;
    }

    api.admin.users.role({
      token: this.props.auth.jwt,
      email
    }).catch(err => {
      console.log(err);
    })

    const users = [...this.state.users];
    const userIndex = users.findIndex(u => u.email === email);

    if (users[userIndex].role === 'user') {
      users[userIndex].role = 'admin';
    } else {
      users[userIndex].role = 'user';
    }

    this.setState({ users: [...users] });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <PageTitle>Users</PageTitle>
        <Paper elevation={0} square className={classes.paper}>
        <Toolbar className={classes.toolbar}>
            <Tooltip title="Create new user">
              <Button onClick={this.openCreateUser} color="primary" variant="contained">
                <AddIcon />
                Create new user
              </Button>
            </Tooltip>
          </Toolbar>
          <div className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Next Booking</TableCell>
                <TableCell>Total Bookings</TableCell>
                {this.props.auth.user.email.includes('@purplerealtime') && (
                  <TableCell>Admin</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.users.map(user => (
            <TableRow key={user._id}>
                  <TableCell>{user.firstname} {user.lastname}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.nextBookingDate ? moment(user.nextBookingDate).format("ddd DD/MM/YYYY") : 'None'}</TableCell>
                  <TableCell>{user.totalBookings}</TableCell>
                  {this.props.auth.user.email.includes('@purplerealtime') && (
                    <TableCell><Switch checked={user.role === 'admin'} onClick={() => this.toggleUserRole(user.email)} /></TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </div>
          <TablePagination component="div" count={this.state.userCount} rowsPerPageOptions={[10]} rowsPerPage={10} page={this.state.page} onPageChange={(e, page) => {this.getUsers({ page })}} />
        </Paper>
        <CreateUserDialog 
          open={this.state.createUserDialog} 
          cancel={this.closeNewUser} 
          confirm={this.createUserSubmit} 
          firstname={this.state.newUser.firstname} 
          lastname={this.state.newUser.lastname} 
          email={this.state.newUser.email} 
          password={this.state.newUser.password} 
          onChange={this.onChange} 
          loading={this.state.createUserLoading} 
          error={this.state.createUserError} 
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default isAdmin(connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Users)));