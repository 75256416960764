import React, { Component } from 'react';
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { connect } from 'react-redux';
import { withStyles } from "@mui/material/styles";
import DateRangeIcon from '@material-ui/icons/DateRange';
import GetAppIcon from '@material-ui/icons/GetApp';
import withWidth from '@mui/material/withWidth';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import rooms from '../config/rooms';
import api from '../api';
import { isAdmin } from '../config/authorization';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 4),
    },
    position: "relative",
  },
  toolbar: {
    border: '1px solid rgba(88, 44, 131, 0.3)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarOptions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  roomSelector: {
    justifyContent: 'space-around',
    [theme.breakpoints.up('lg')]: {
      minWidth: '500px',

    },
    "& .MuiFormControlLabel-root" : {
       [theme.breakpoints.down('md')]: {
        width: '25%',
        minWidth: '60px',
        display: 'flex',
        margin: '0px',
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        // paddingTop: '12px',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: '12px',
      }
    },
    "& .MuiCheckbox-root": {
      [theme.breakpoints.down('md')]: {
        padding: '0px',
      }
    } 
  },
  dateSelector: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableContainer: {
    display: 'block',
    overflow: 'auto',
  },
});

class AllBookings extends Component {
  state = {
    bookings: [],
    start: null,
    end: null, 
    focusedInput: null,
    cancelDialog: false,
    cancelBooking: {},
    cancelRoom: {},
    rooms: [],
  }

  componentDidMount() {
    const today = moment().startOf('day');
    const nextMonth = moment().startOf('day').add(1, 'month');
    const selectedRooms = rooms.map(r => r.id);

    this.setState({ start: today, end: nextMonth, rooms: selectedRooms });
    this.getBookings({ start: today, end: nextMonth });
  }

  getBookings = ({ start, end }) => {
    api.admin.booking.get({ token: this.props.auth.jwt, start: start.format(), end: end.format() })
    .then(res => {
      this.setState({ bookings: [...res.data.bookings] })
    }).catch(err => {
      console.log(err);
    })
  }

  focusedInputChange = (focus) => {
    this.setState({ focusedInput: focus });
  }

  datesChanged = ({ startDate, endDate }) => {
    this.setState({ start: startDate, end: endDate })
    if (this.state.focusedInput === 'endDate' && startDate && endDate) {
      this.getBookings({ start: startDate, end: endDate });
    }
  }

  downloadBookings = () => {
    api.admin.booking.download({
      token: this.props.auth.jwt,
      start: this.state.start ? this.state.start.format() : null,
      end: this.state.end ? this.state.end.format() : null,
      rooms: this.state.rooms
    }).then(response => {
      const selectedRooms = rooms.filter(r => this.state.rooms.includes(r.id)).map(r => r.name);
      const selectedRoomsString = selectedRooms.join('_');
      const start = this.state.start ? this.state.start.format("DD_MM_YYYY") : '';
      const end = this.state.end ? this.state.end.format("DD_MM_YYYY") : '';
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Oxford Bookings - ${start} - ${end} - ${selectedRoomsString}.csv`);
      link.click();
      window.URL.revokeObjectURL(url);
    })
  }

  changeRooms = (event) => {
    const selectedRooms = [...this.state.rooms];

    const index = selectedRooms.findIndex(e => e === event.target.name);
    if (index !== -1) {
      // remove from selection
      selectedRooms.splice(index, 1);
    } else {
      // add to selection
      selectedRooms.push(event.target.name);
    }

    this.setState({ rooms: selectedRooms });

    // setState({ ...state, [event.target.name]: event.target.checked });
  }

  deleteBooking = () => {
    api.admin.booking.delete({ 
      token: this.props.auth.jwt, 
      id: this.state.cancelBooking._id, 
      room: this.state.cancelRoom.id 
    }).then(() => {
      this.getBookings({ start: this.state.start, end: this.state.end });
      this.closeCancelDialog();
    })
  }

  openCancelDialog = (booking, room) => {
    console.log(booking)
    this.setState({ cancelDialog: true, cancelBooking: {...booking}, cancelRoom: {...room} })
  }

  closeCancelDialog = () => {
    this.setState({ cancelDialog: false, cancelBooking: {}, cancelRoom: {} })
  }

  render() {
    const { classes } = this.props;
    const selectedRooms = rooms.filter(r => this.state.rooms.includes(r.id));

    return (
      <div>
        <PageTitle>All Bookings</PageTitle>
        <Paper elevation={0} square className={classes.paper}>
          <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarOptions}>

            <FormGroup row className={classes.roomSelector}>
              {rooms.map(r => (
                <FormControlLabel
                  key={r.id}
                  control={<Checkbox name={r.id} onChange={this.changeRooms} checked={this.state.rooms.includes(r.id)} />}
                  label={r.name}
                />
              ))}
            </FormGroup>
            <div className={classes.dateSelector}>
              <div style={{display: 'none'}}>
              <DateRangePicker
                startDate={this.state.start} // momentPropTypes.momentObj or null,
                startDateId="check_in_date" // PropTypes.string.isRequired,
                endDate={this.state.end} // momentPropTypes.momentObj or null,
                endDateId="check_out_date" // PropTypes.string.isRequired,
                onDatesChange={this.datesChanged} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={this.focusedInputChange} // PropTypes.func.isRequired,
                numberOfMonths={ this.props.width == 'sm' ||  this.props.width == 'xs' ? 1 : 2}
                withPortal={true}
                isOutsideRange={() => false}
              />
            </div>
            {/* <div> */}
            {this.state.start && this.state.start.format("Do MMM YYYY")} - {this.state.end && this.state.end.format("Do MMM YYYY")}
            {/* </div> */}
            <Tooltip title="Date range">
              <IconButton onClick={() => this.setState({ focusedInput: 'startDate' })}>
                <DateRangeIcon />
              </IconButton>
            </Tooltip>
            </div>
            </div>

            <Tooltip title="Download">
              <IconButton onClick={this.downloadBookings}>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <div className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Room</TableCell>
                <TableCell>Check-in</TableCell>
                <TableCell>Check-out</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Dog</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.bookings.map(booking => {
                const currentRooms = selectedRooms.filter(r => booking.rooms.includes(r.id));
                return (
                  <React.Fragment key={booking._id}>
                    {currentRooms.map(room => (
                      <TableRow key={room.id}>
                        <TableCell>{room.name}</TableCell>
                        <TableCell>{moment(booking.checkin).format("ddd DD/MM/YYYY")}</TableCell>
                        <TableCell>{moment(booking.checkout).format("ddd DD/MM/YYYY")}</TableCell>
                        <TableCell>{booking.name}</TableCell>
                        <TableCell>{booking.email}</TableCell>
                        <TableCell>{!!booking.dog ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{moment(booking.checkout).isAfter(moment()) && (
                          <>
                            <IconButton style={{ color: '#ff0000' }} onClick={() => this.openCancelDialog(booking, room)}>
                              <CancelIcon />
                            </IconButton>
                          </>
                        )}</TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
          </div>
        </Paper>
        <Dialog open={this.state.cancelDialog} onClose={this.closeCancelDialog}>
          <DialogTitle>Are you sure you want to delete this booking?</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              <strong>Room: </strong>{this.state.cancelRoom.name}
            </DialogContentText>
            <DialogContentText>
              {moment(this.state.cancelBooking.checkin).format("ddd DD/MM/YYYY")} - {moment(this.state.cancelBooking.checkout).format("ddd DD/MM/YYYY")}
            </DialogContentText>
            <DialogContentText>
              {this.state.cancelBooking.name} ({this.state.cancelBooking.email})
            </DialogContentText>
              <Typography variant="body2" style={{fontStyle: 'italic'}} >This action is not reversable, once a booking is gone it will have to be re-booked to get it back.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeCancelDialog}>Cancel</Button>
            <Button variant="contained" style={{backgroundColor: '#ff0000', color: '#fff'}} onClick={this.deleteBooking}>DELETE</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default isAdmin(withWidth()(connect(mapStateToProps)((withStyles(styles, { withTheme: true })(AllBookings)))));