import React, { Component } from 'react';
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { withStyles } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import moment from 'moment';
import Swal from 'sweetalert2';
import api from '../api';
import rooms from '../config/rooms';
import { Link } from 'react-router-dom';


import { Button, FormControlLabel, Switch, TextField, Typography } from '@mui/material';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(8, 4),
    position: "relative",
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginLeft: '10px',
  },
  room: {
    padding: '0 5px',
    borderRight: '1px solid #000',
    "&:first-of-type": {
      paddingLeft: 0
    },
    "&:last-of-type": {
      paddingRight: 0,
      borderRight: 0
    }
  },
  selectDayContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  selectDay: {
    border: '1px solid #ccc',
    backgroundColor: '#eee',
    color: '#333',
    display: 'inline-flex',
    width: '100px',
    height: '100px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '1.2rem',
    lineHeight: 1,
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '10px',
    marginBottom: '10px',
    // margin: '0 10px',
    // "&:first-of-type": {
    //   marginLeft: '0'
    // },
    "&[data-selected='true']": {
      backgroundColor: theme.palette.primary.main,
      color: '#eee'
    },
    "& span:last-of-type": {
      fontSize: '2rem'
    }
  }
});

class BookRoomForm extends Component {
  state = {
    name: '',
    people: '',
    number: '',
    instructions: '',
    working: false,
    dog: false,
    workDays: [],
    users: [],
    user: null,
    error: '',
  };

  componentDidMount() {
    if (!this.props.booking.rooms.length) {
      this.props.history.push('/book');
    }

    const queryParams = new URLSearchParams(this.props.location.search);
    const working = queryParams.get('working');

    if (working) {
      this.setState({ working: true })
    }

    if (this.props.auth.user.role === 'admin') {
      this.getUsers({ search: '' });
    }
  }

  getUsers = ({ search }) => {
    api.admin.users.search({ token: this.props.auth.jwt, search })
    .then(res => {
      this.setState({ users: [...res.data.users] })
      console.log(res.data.users);
    }).catch(err => {
      console.log(err);
    })
  }
  
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value, error: '' });
  };

  toggleChecked = e => {
    this.setState({ [e.target.name]: !this.state[e.target.name], error: '' });
  }

  toggleWorkDay = (day) => {
    const workDays = [...this.state.workDays];
    const index = workDays.findIndex(e => e === day);
    if (index !== -1) {
      // remove from selection
      workDays.splice(index, 1);
    } else {
      // add to selection
      workDays.push(day);
    }

    this.setState({ workDays: [...workDays] })
  }

  onSubmit = e => {
    e.preventDefault();


    console.log(this.state);
    // return;

    // from, to, rooms, name, partyCount, contactNumber, instructions, token

    api.booking.create({ 
      from: this.props.booking.from.format(), 
      to: this.props.booking.to.format(),
      rooms: this.props.booking.rooms,
      name: this.state.name,
      partyCount: this.state.people,
      contactNumber: this.state.number,
      instructions: this.state.instructions,
      workDays: this.state.workDays,
      dog: this.state.dog,
      user: this.state.user?._id || null,
      token: this.props.auth.jwt
    }).then(res => {
      Swal.fire({
        title: 'Thanks!',
        text: 'Your booking has been confirmed. You will get an email confirmation shortly.',
        icon: 'success',
        showConfirmButton: true
      }).then(() => {
        this.props.history.push('/bookings');
      })
    }).catch(err => {
      const errMessage = err.response?.data?.msg || "Something went wrong";
      this.setState({ error: errMessage });
    })
  }

  render() {
    const { classes } = this.props;
    const selectedRooms = this.props.booking.rooms.length ? rooms.filter(r => this.props.booking?.rooms?.includes(r.id)) : [];
    const days = [];
    if (this.props.booking.rooms.length) {
      const difference = this.props.booking.to.diff(this.props.booking.from, 'days');
      for (let index = 0; index < difference+1; index++) {
        const newDay = moment(this.props.booking.from).add(index, 'days');
        days.push(newDay);
      }
    }

    return (
      <div>
        <PageTitle>Confirm Booking</PageTitle>
        <Paper elevation={0} square className={classes.paper}>
          <form onSubmit={this.onSubmit}>
            <Grid container spacing={3}>
              {this.props.booking.rooms.length && (
                <Grid item xs={12}>
                  <b>Dates: </b>{this.props.booking.from.format("MMM DD, YYYY")} - {this.props.booking.to.format("MMM DD, YYYY")} <br />
                  <b>Rooms: </b> {selectedRooms.map(r => (
                    <span key={r.id} className={classes.room}>{r.name}</span>
                  ))}
                </Grid>
              )}
              {this.state.error && (
                <Grid item xs={12}>
                  <span style={{color: 'red'}}>{this.state.error}</span>
                </Grid>
              )}
              {this.props.auth.user.role === 'admin' && (
                <Grid item xs={12}>
                  <Autocomplete 
                    options={this.state.users}
                    onChange={(e, value) => this.setState({ user: value })}
                    getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                    getOptionSelected={(a, b) => a._id === b._id}
                    value={this.state.user}
                    renderInput={(params) => <TextField {...params} label="On behalf of" variant="outlined" />}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField label="Name of main person" onChange={this.onChange} name="name" variant="outlined" fullWidth/>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Number of people in party" onChange={this.onChange} name="people" variant="outlined" fullWidth/>
                </Grid>
              <Grid item xs={12}>
                <TextField label="Contact number" onChange={this.onChange} name="number" variant="outlined" fullWidth/>
                </Grid>
              <Grid item xs={12}>
                <TextField label="Special instructions" onChange={this.onChange} name="instructions" multiline rows={4} variant="outlined" fullWidth/>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel 
                  control={<Switch checked={this.state.dog} name="dog" onChange={this.toggleChecked} />}
                  label="Are you bringing a dog with you?"
                />
                <Typography variant='body2'>
                Dogs are permitted however the Bagpuize House Estate require this information before you arrive
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={this.state.working} name="working" onChange={this.toggleChecked} />}
                  label="Are you working on any of these days?"
                />
              </Grid>
              {this.state.working && (
                <Grid item lg={12} className={classes.selectDayContainer}>
                  {days.map(day => (
                    <div className={classes.selectDay} onClick={() => this.toggleWorkDay(day.format())} data-selected={this.state.workDays.includes(day.format())} key={day.unix()}>
                      <span>{day.format("MMM")}</span>
                      <span>{day.format("Do")}</span>
                    </div>
                  ))}
                </Grid>
              )}
              <Grid item xs={12}>
                <Button color="primary" type="submit" variant="contained">Complete</Button>
                <Button component={Link} className={classes.link} to="/book">Cancel booking</Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth, booking } = state;
  return {
    auth,
    booking
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(BookRoomForm));
