import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { logUserIn } from '../actions/auth'; 
import logo from '../assets/logo.png'

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  }
});

class Login extends Component {

  state = {
    email: '',
    password: '',
    error: false,
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value, error: false });
  };

  submit = e => {
    e.preventDefault();
    this.props.logUserIn({
      email: this.state.email,
      password: this.state.password
    }).catch(err => {
      this.setState({error: err.response.data.msg})
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form noValidate className={classes.form} onSubmit={this.submit}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.onChange}
            value={this.state.email}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
            helperText={this.state.error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.onChange}
            value={this.state.password}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={this.props.auth.isLoading}
          >
            {this.props.auth.isLoading && <CircularProgress size={20} className={classes.circularProgress} />} Sign In
          </Button>
        </form>
        {/* <Link className={classes.link} to="/signup">Not got an account?</Link> */}
        <Link className={classes.link} to="/forgotten-password">Forgotten password?</Link>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, { logUserIn })(withStyles(styles, { withTheme: true })(Login));