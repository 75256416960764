import React, { Component } from 'react';
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import { connect } from 'react-redux';
import { withStyles } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import Swal from 'sweetalert2';
import api from '../api';
import CheckAvailability from '../components/CheckAvailability';
import CalLegend from '../components/CalLegend';
import IconPeople from '../assets/people.svg';
import rooms from '../config/rooms';
import moment from 'moment';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 4),
    },
    position: "relative",
    
    "& .fullWidth": {
      width: '100%',
      // margin: 'auto'
    },
    "& .fc .fc-scrollgrid-section-sticky > *": {
      zIndex: '1',
    },
    "& .fc-bg-event": {
      pointerEvents: 'none',
    },
    "& .event-office.fc-event-start": {
        backgroundImage: `url(${IconPeople})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '22px',
        height: '22px',
        opacity: 1,
        borderRadius: "23px",
        backgroundColor: '#582C83',
        border: '3px solid #582C83',
        margin: '2px 4px',
    },
    "& .event-red": {
      height: '14%',
      top: '38%',
      opacity: 1,
      borderRadius: "3px",
    },
    "& .event-red.fc-event-start": {
      borderLeft: `15px solid rgba(${rooms[0].color}, 0.8)`,
    },
    "& .event-red.fc-event-end": {
      borderRight: `15px solid rgba(${rooms[0].color}, 0.8)`,
    },
    "& .event-green": {
      height: '14%',
      top: '54%',
      opacity: 1,
      borderRadius: "3px",
    },
    "& .event-green.fc-event-start": {
      borderLeft: `15px solid rgba(${rooms[1].color}, 0.8)`,
    },
    "& .event-green.fc-event-end": {
      borderRight: `15px solid rgba(${rooms[1].color}, 0.8)`,
    },
    "& .event-blue": {
      height: '14%',
      top: '70%',
      opacity: 1,
      borderRadius: "3px",
    },
    "& .event-blue.fc-event-start": {
      borderLeft: `15px solid rgba(${rooms[2].color}, 0.8)`,
    },
    "& .event-blue.fc-event-end": {
      borderRight: `15px solid rgba(${rooms[2].color}, 0.8)`,
    },
    "& .event-yellow": {
      height: '14%',
      top: '86%',
      opacity: 1,
      borderRadius: "3px",
    },
    "& .event-yellow.fc-event-start": {
      borderLeft: `15px solid rgba(${rooms[3].color}, 0.8)`,
    },
    "& .event-yellow.fc-event-end": {
      borderRight: `15px solid rgba(${rooms[3].color}, 0.8)`,
    },
    "& .fc .fc-bg-event .fc-event-title": {
      margin: '0'
    },
    "& .fc .fc-daygrid-day.fc-day-today": {
      backgroundColor: 'rgba(0, 0, 0, 0.15)'
    },
    "& .fc-daygrid-day:hover": {
      backgroundColor: 'rgba(88, 44, 131, 0.3) !important',
      cursor: 'pointer',
    },
    "& .fc-day-past": {
     backgroundColor: 'rgba(0, 0, 0, 0.035)'
    },
    "& .fc-day-past:hover": {
      backgroundColor: 'initial !important',
      cursor: 'not-allowed',
    }
  },
  buttonDialog: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    padding: '20px',
    "& button": {
      margin: theme.spacing(1, 1),
    }
  }
});

class Home extends Component {
  state = {
    events: [],
    dateDialog: false,
    date: null,
    calStart: null,
    calEnd: null
  };

  componentDidMount() {


  }

  updateBookings = ({start, end}) => {
    api.booking.get({
      from: start,
      to: end,
      token: this.props.auth.jwt
    }).then(res => {
      const events = res.data.bookings.reduce((acc, cur) => {
        cur.rooms.forEach(room => {
          const findRoom = rooms.find(r => r.id === room);
          if (findRoom) {
            acc.push({
              classNames: [findRoom.class],
              start: cur.checkin,
              end: cur.checkout,
              backgroundColor: `rgba( ${findRoom.color} , 0.5 )`,
              display: 'background',
              allDay: true
            })
          }
        })
        return acc;
      }, [])

      const workDays = res.data.working.reduce((acc, cur) => {
        if (cur.date) {
          acc.push({
            classNames: ['event-office'],
            start: moment(cur.date).toDate(),
            end: moment(cur.date).toDate(),
            display: 'background',
            allDay: true
          })
        }
        return acc;
      }, [])
      this.setState({ events: [...events, ...workDays], calStart: start, calEnd: end });
    }).catch(err => {
      console.log(err);
    })
  }

  submitAvailability = ({start, end}) => {
    this.props.history.push(`/book?start=${start.toISOString()}&end=${end.toISOString()}`);
  }

  datesChanged = ({startStr, endStr}) => {
    this.updateBookings({ start: startStr, end: endStr });
  }

  dateClick = (date) => {
    if (moment(date.date).isBefore(moment().startOf('day'))) return;
    this.setState({ date: date.date, dateDialog: true })
  }

  closeDateDialog = () => {
    this.setState({ date: null, dateDialog: false })
  }

  goToNewBooking = () => {
    console.log(moment(this.state.date).toISOString());
    this.props.history.push(`/book?start=${moment(this.state.date).toISOString()}`)
  }

  bookWorkDay = () => {
    const normalizedDates = [moment(this.state.date).format()];
    api.working.create({
      dates: normalizedDates,
      token: this.props.auth.jwt
    }).then(() => {
      Swal.fire({
        title: 'Thanks!',
        text: 'Your work day has been confirmed.',
        icon: 'success',
        showConfirmButton: true
      }).then(() => {
        this.updateBookings({ start: this.state.calStart, end: this.state.calEnd })
        this.closeDateDialog()
      })
    }).catch(err => console.log(err));
  }

  goToNewBookingWithDay = () => {
    this.props.history.push(`/book?start=${moment(this.state.date).toISOString()}&working=true`)
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {/* <PageTitle>Welcome</PageTitle> */}
        <Paper elevation={0} square className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Welcome {this.props.auth.user.firstname},</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item sm={6} className='fullWidth'>
                  <CheckAvailability onSubmit={this.submitAvailability}/>
                </Grid>
                <Grid item sm={6} className='fullWidth'>
                  <CalLegend />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <FullCalendar 
              className={classes.calendar}
              plugins={[ dayGridPlugin, interactionPlugin ]}
              initialView="dayGridMonth"
              firstDay='1'
              datesSet={this.datesChanged}
              events={this.state.events}
              height="auto"
              dateClick={this.dateClick}
              headerToolbar={{
                left: 'title',
                center: '',
                right: 'prev next'
              }}
            />
            </Grid>
          </Grid>
        </Paper>
        <Dialog onClose={this.closeDateDialog} open={this.state.dateDialog}>
          <DialogContent className={classes.buttonDialog}>
              <Button variant="contained" color="primary" onClick={this.goToNewBooking}>New Booking</Button>
              <Button variant="contained" color="primary" onClick={this.bookWorkDay}>New Work Day</Button>
              <Button variant="contained" color="primary" onClick={this.goToNewBookingWithDay}>New Booking & Work Day</Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Home));
