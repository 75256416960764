import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, TextField, Checkbox, Typography, CircularProgress,FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { signUserUp } from '../actions/auth';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
});

class Signup extends Component {

  state = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    terms: false,
    confirmpassword: '',
    error: false,
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value, error: false });
  };

  submit = e => {
    e.preventDefault();
    this.props.signUserUp({
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      password: this.state.password,
      confirmpassword: this.state.confirmpassword,
      terms: this.state.terms,
    }).catch(err => {
      this.setState({error: err.response.data.msg})
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form noValidate className={classes.form} onSubmit={this.submit}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstname"
            label="First name"
            name="firstname"
            autoComplete="firstname"
            autoFocus
            onChange={this.onChange}
            value={this.state.firstname}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
            helperText={this.state.error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastname"
            label="Last name"
            name="lastname"
            autoComplete="lastname"
            onChange={this.onChange}
            value={this.state.lastname}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
            helperText={this.state.error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={this.onChange}
            value={this.state.email}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
            helperText={this.state.error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.onChange}
            value={this.state.password}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmpassword"
            label="Confirm password"
            type="password"
            id="confirmpassword"
            autoComplete="confirm-password"
            onChange={this.onChange}
            value={this.state.confirmpassword}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
          />
          <FormControl error={!!this.state.error}>
              <FormControlLabel
                control={<Checkbox name="terms" />}
                checked={this.state.terms}
                disabled={this.state.loading}
                onChange={(e) => this.onChange({ target: { name: 'terms', value: e.target.checked } })}
                label={<React.Fragment>I agree with the <a className={classes.link} href="/terms" target="_blank">terms and conditions</a> and the <a className={classes.link} href="/privacy" target="_blank">privacy policy</a></React.Fragment>}
              />
              {(!this.state.field || this.state.field === 'terms') && !!this.state.error && (<FormHelperText>{this.state.error}</FormHelperText>)}
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={this.props.auth.isLoading}
          >
            {this.props.auth.isLoading && <CircularProgress size={20} className={classes.circularProgress} />} Create account
          </Button>
        </form>
        <Link className={classes.link} to="/login">Already have an account?</Link>
        <Link className={classes.link} to="/forgotten-password">Forgotten password?</Link>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, { signUserUp })(withStyles(styles, { withTheme: true })(Signup));