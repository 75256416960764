import React, { Component } from 'react';
import PageTitle from "../components/PageTitle";
import ConfirmDialog from '../components/ConfirmDialog';
import Paper from "@mui/material/Paper";
import { connect } from 'react-redux';
import { withStyles } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import moment from 'moment';
import rooms from '../config/rooms';
import api from '../api';
import DeleteIcon from '@material-ui/icons/DeleteForever';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 4),
    },    position: "relative",
  },
  deleteBookingContainer: {
    display: "flex",
    justifyContent: "right",
    width: "100%",
    "& svg": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    "& svg:hover": {
      cursor: "pointer",
      color: theme.palette.primary.action.hover,
    }
  },
  booking: {
    display: "flex",
    flexWrap: "wrap",
    padding: theme.spacing(2, 2),
    border: "1px solid #582C83",
    borderRadius: "5px"
  },
  pastBooking: {
    "& > div": {
    border: "1px solid #999999",
    color: "#999999",
    "& svg": {
      cursor: "not-allowed",
      color: "#999999",
    },
    "& svg:hover": {
      cursor: "not-allowed",
      color: "#999999",
    }
  },
  },
  bookingSection: {
    padding: theme.spacing(1, 0),
  },
  bookingData: {
    fontSize: "1.3rem",
    fontWeight: "500",
  },
  bookingDataInstructions: {
    fontSize: "1rem",
    fontWeight: "500",
    minHeight: "2rem"
  }
});

class Bookings extends Component {
  state = {
    bookings: [],
    openDelete: false,
    deleteId: null,
    deleteBookingText: ''
  }

  componentDidMount() {
    api.booking.mine({ token: this.props.auth.jwt })
      .then(res => {
        this.setState({ bookings: [...res.data.bookings] })
      }).catch(err => {
        console.log(err);
      })
  }

  openDeleteBooking = (id) => {
    const bookings = [...this.state.bookings];
    const index = bookings.findIndex(e => e._id === id);
    if (index === -1) return;
    const booking = bookings[index];
    const currentRooms = rooms.filter(r => booking.rooms.includes(r.id)).map(r => r.name);
    const text = `You are about to delete your booking of ${currentRooms.join(', ')} from ${moment(booking.checkin).format("ddd Do MMM, YYYY")} to ${moment(booking.checkout).format("ddd Do MMM, YYYY")}. This cannot be undone.`

    this.setState({ openDelete: true, deleteId: id, deleteBookingText: text });
  }

  closeDeleteDialog = () => {
    this.setState({ openDelete: false, deleteId: null, deleteBookingText: '' });
  }

  confirmDelete = () => {
    const bookings = [...this.state.bookings];
    const bookingId = this.state.deleteId;
    const index = bookings.findIndex(e => e._id === bookingId);
    if (index === -1) return;


    api.booking.delete({ id: bookingId, token: this.props.auth.jwt }).then(res => {
      bookings.splice(index, 1);
      this.setState({ bookings })
    }).catch(err => console.log(err))

    this.closeDeleteDialog();
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <PageTitle>My Bookings</PageTitle>
        <Paper elevation={0} square className={classes.paper}>
          <Grid container spacing={3}>
            {this.state.bookings.map(booking => {
              const currentRooms = rooms.filter(r => booking.rooms.includes(r.id));
              const isPastBooking = moment(booking.checkin).isBefore(moment());
              return (
                <Grid item xs={12} key={booking._id} className={isPastBooking ? classes.pastBooking : "" }>
                      <div className={classes.booking}>
                        <div className={classes.deleteBookingContainer}>
                          <DeleteIcon onClick={() => isPastBooking ? {} : this.openDeleteBooking(booking._id)} />
                        </div>
                      <Grid item xs={12} sm={6} lg={3} className={classes.bookingSection}>
                      Check-in
                      <div className={classes.bookingData}>
                        {moment(booking.checkin).format("ddd Do MMM, YYYY")}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} className={classes.bookingSection}>
                      Check-out
                      <div className={classes.bookingData}>
                        {moment(booking.checkout).format("ddd Do MMM, YYYY")}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} className={classes.bookingSection}>
                      Name
                      <div className={classes.bookingData}>
                        {booking.name}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} className={classes.bookingSection}>
                      Number in party
                      <div className={classes.bookingData}>
                        {booking.partyCount}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} className={classes.bookingSection}>
                      Rooms booked
                      <div className={classes.bookingData}>
                        {currentRooms.map(r => (<span key={r.id} >{r.name}<br /></span>))}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} className={classes.bookingSection}>
                      Contact Number
                      <div className={classes.bookingData}>
                        {booking.contactNumber}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3} className={classes.bookingSection}>
                      Bringing a dog
                      <div className={classes.bookingData}>
                        {!!booking.dog ? 'YES' : 'NO'}
                      </div>
                    </Grid>
                    <Grid item xs={12} className={classes.bookingSection}>
                      Special instructions
                      <div className={classes.bookingDataInstructions}>
                        {booking.instructions}
                      </div>
                    </Grid>
                  </div>

                </Grid>
              )
            })}
          </Grid>
        </Paper>
        <ConfirmDialog 
          open={this.state.openDelete} 
          cancel={this.closeDeleteDialog} 
          confirm={this.confirmDelete} 
          title="Are you sure you want to delete this booking?" 
          text={this.state.deleteBookingText}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Bookings));