import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker, DayPickerSingleDateController } from 'react-dates';
import { withStyles } from "@mui/material/styles";
import withWidth from '@mui/material/withWidth';
import { Button } from '@mui/material';
import moment from 'moment';

const styles = (theme) => ({
  container: {
    width: '100%',
    border: '1px solid rgba(88, 44, 131, 0.3)',
    borderRadius: '5px',
    padding: '10px',
    position: 'relative',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    // [theme.breakpoints.up('lg')]: {
    //   flexDirection: 'row'
    // },

    "& .SingleDatePicker": {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        flex: 1,
      },
    },

    "& .SingleDatePickerInput.SingleDatePickerInput__withBorder": {
      borderRadius: '10px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    },

    "& .DateInput_input.DateInput_input_1": {
      borderRadius: '10px',
    },

    "& .SingleDatePickerInput .DateInput": {
      background: 'transparent'
    },
  },
  checkButton: {
    height: '46px',
    marginTop: '10px',
    // background: '#582C83',
    background: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '10px',
    // width: '100%',
    lineHeight: '1',
    // [theme.breakpoints.up('lg')]: {
    //   marginTop: '0px',
    //   marginLeft: '10px',
    //   width: 'auto'
    // },
    "&:hover": {
      background: theme.palette.primary.action.hover,
      },
      "&.Mui-disabled": {
      background: '#c3c3c3',
      }
  },
});

class AddWorkDays extends Component {
  state = {
    start: null,
    end: null,
    dates: [],
    focusedInput: null
  }  

  onSubmit = () => {
    // if (!this.state.dates.length) return;
    this.props.onSubmit({ dates: this.state.dates });
    this.setState({ dates: [], focusedInput: null });
  }

  addOrRemoveDate = (date) => {
    if (date.isBefore(moment().startOf('day'))) return;
    const dates = [...this.state.dates];
    const index = dates.findIndex(d => d.isSame(date, "day"));
    if (index !== -1) {
      // remove from selection
      dates.splice(index, 1);
    } else {
      // add to selection
      dates.push(date);
    }

    this.setState({ dates });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <DayPickerSingleDateController
          date={null}
          onDateChange={this.addOrRemoveDate} // PropTypes.func.isRequired,
          focused={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={({ focused }) => this.setState({ focusedInput: focused })} // PropTypes.func.isRequired,
          // numberOfMonths={ this.props.width == 'sm' ||  this.props.width == 'xs' ? 1 : 2}
          numberOfMonths={2}
          isDayHighlighted={(day) => this.state.dates.some(d => d.isSame(day, "day"))}
          isDayBlocked={(day) => this.props.dates.some(d => moment(d).isSame(day, "day"))}
          isOutsideRange={(day) => day.isBefore(moment().startOf('day'))}
          keepOpenOnDateSelect
        />
        <Button onClick={this.onSubmit} className={classes.checkButton} variant="outlined" disabled={!this.state.dates.length}>Add Work Days</Button>
      </div>
    );
  }
}

export default withWidth()( withStyles(styles, { withTheme: true })(AddWorkDays))