import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { forgottenPassword } from '../actions/auth';

const MySwal = withReactContent(Swal)

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  }
});

class ForgottenPassword extends Component {

  state = {
    email: '',
    error: false,
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value, error: false });
  };

  submit = e => {
    e.preventDefault();
    this.props.forgottenPassword({
      email: this.state.email,
    }).then(() => {
      MySwal.fire({
        title: 'Thanks!',
        text: 'If your email address is found, you\'ll get an email with a link to reset your password shortly.',
        icon: 'success',
        showConfirmButton: true
      }).then(() => {
        this.props.history.push('/login');
      })
    }).catch(err => {
      console.log({err});
      this.setState({error: err.response.data.msg || err.response.statusText})
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        <Typography component="h1" variant="h5">
          Forgotten Password
        </Typography>
        <form noValidate className={classes.form} onSubmit={this.submit}>
        <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={this.onChange}
            value={this.state.email}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
            helperText={this.state.error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={this.props.auth.isLoading}
          >
            {this.props.auth.isLoading && <CircularProgress size={20} className={classes.circularProgress} />} Reset password
          </Button>
        </form>
        <Link className={classes.link} to="/login">Login to your account</Link>
        {/* <Link className={classes.link} to="/signup">Create new account</Link> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, { forgottenPassword })(withStyles(styles, { withTheme: true })(ForgottenPassword));