import React from 'react';
import { withStyles } from "@mui/material/styles";
import { CircularProgress } from '@mui/material';

const styles = (theme) => ({
  background: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.modal,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const Loading = ({ classes }) => {

  return (
    <div className={classes.background}>
      <CircularProgress size={100} />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Loading);