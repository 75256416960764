/* eslint-disable */
import axios from 'axios';

const hostname = window.location.hostname;

const url = process.env.NODE_ENV === 'production' ? '/api' : `http://${hostname}:3000/api`;
// const url = `https://${hostname}`;

const logoutIfNotAuthenticated = (e) => {

}

export default {
  auth: {
    login: ({ email, password }) => axios.post(`${url}/login`, { email, password }),
    signup: ({ firstname, lastname, email, password, confirmpassword, terms }) => axios.post(`${url}/signup`, { firstname, lastname, email, password, confirmpassword, terms }),
    forgottenPassword: ({ email }) => axios.post(`${url}/forgottenpassword`, { email }),
    checkResetCode: ({ code }) => axios.post(`${url}/checkresetcode`, { code }),
    resetPassword: ({ code, password }) => axios.post(`${url}/resetpassword`, { code, password }),
    user: ({ token }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.get(`${url}/user`, { headers });
    },
    createPassword: ({ token, password, terms }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.post(`${url}/createpassword`, { password, terms }, {headers})
    },
  },
  rooms: {
    checkAvailability: ({ from, to, token }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.post(`${url}/availability`, {from, to}, {headers})
    }
  },
  booking: {
    create: ({ from, to, rooms, name, partyCount, contactNumber, instructions, workDays, user, dog, token }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.post(`${url}/bookings`, {from, to, rooms, name, partyCount, contactNumber, instructions, workDays, user, dog}, {headers})
    },
    get: ({ from, to, token }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.get(`${url}/bookings`, { headers, params: { from, to } });
    },
    mine: ({ token }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.get(`${url}/mybookings`, { headers });
    },
    delete: ({ id, token }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.post(`${url}/bookings/delete`, {id}, {headers})
    }
  },
  working: {
    create: ({ from, to, dates, token }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.post(`${url}/working`, {from, to, dates}, {headers})
    },
    mine: ({ token }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.get(`${url}/working`, { headers });
    },
    delete: ({ id, token }) => {
      const headers = { Authorization: `Bearer ${token}` };
      return axios.post(`${url}/working/delete`, {id}, {headers})
    }
  },
  admin: {
    booking: {
      get: ({ token, start, end }) => {
        const headers = { Authorization: `Bearer ${token}` };
        return axios.get(`${url}/bookings/all`, { headers, params: { start, end } });
      },
      download: ({ token, start, end, rooms }) => {
        const headers = { Authorization: `Bearer ${token}` };
        return axios({method: 'post', url: `${url}/bookings/all/download`, responseType: 'blob', data: {start, end, rooms}, headers})
      },
      delete: ({ token, id, room }) => {
        const headers = { Authorization: `Bearer ${token}` };
        return axios({method: 'post', url: `${url}/bookings/all/delete`, data: {id, room}, headers})
      }
    },
    users: {
      get: ({ token, page }) => {
        const headers = { Authorization: `Bearer ${token}` };
        return axios.get(`${url}/users`, { headers, params: { page } });
      },
      search: ({ token, search }) => {
        const headers = { Authorization: `Bearer ${token}` };
        return axios.get(`${url}/users/search`, { headers, params: { search } });
      },
      create: ({ token, firstname, lastname, email, password }) => {
        const headers = { Authorization: `Bearer ${token}` };
        return axios.post(`${url}/user`, {firstname, lastname, email, password}, {headers})
      },
      role: ({ token, email }) => {
        const headers = { Authorization: `Bearer ${token}` };
        return axios.post(`${url}/user/role`, {email}, {headers})
      }
    }
  }
}
