import React, { Component } from 'react';
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import Carousel from 'react-material-ui-carousel'
import { connect } from 'react-redux';
import { withStyles } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import rooms from '../config/rooms';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 4),
    },    position: "relative",
  },
  roomContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #bbb',
    borderRadius: '5px',
    position: 'relative',
    overflow: 'hidden',
    height: '100%'
  },
  image: {
    width: '100%',
    borderRadius: '5px 5px 0px 0px',
    // margin: theme.spacing(0, 0, 2, 0)
  },
  infoArea: {
    padding: theme.spacing(3),
    flex: 1
  },
  header: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  info: {
    display: 'block',
    padding: theme.spacing(0.5),
  },
  facilitiesContainer: {
    margin: theme.spacing(2, 0, 1, 0),
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  facilities: {
    border: '1px solid #582C83',
    color: '#582C83',
    borderRadius: '5px',
    padding: '3px 6px',
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '5px',
  },
});

class Rooms extends Component {

  render() {
    const { classes } = this.props;
    return (
      <div>
        <PageTitle>Room Information</PageTitle>
        <Paper elevation={0} square className={classes.paper}>
          <Grid container spacing={3}>

            {rooms.map(room => (
              <Grid item sm={6} xl={3} key={room.id}>
                <div className={classes.roomContainer} >
                  <Carousel
                    autoPlay={false}
                    animation="slide"
                  >
                    {
                      room.gallery.map((image, i) => (
                        <img key={`${room.id}${i}`} className={classes.image} src={image} alt={room.name} />
                      ))
                    }
                  </Carousel>
                  <div className={classes.infoArea}>
                    <h2 className={classes.header} >{room.name}</h2>
                    <span className={classes.info}><strong>Sleeps:</strong> {room.sleeps}</span>
                    {/* <span className={classes.info}><strong>Ensuite:</strong> {room.ensuite ? 'Yes' : 'No'}</span> */}
                    <span className={classes.info}><strong>Bathroom:</strong> {room.bathrooms}</span>
                    <span className={classes.info}><strong>Room facilities: ​</strong> {room.facilities}</span>

                    <div className={classes.facilitiesContainer} >
                      <span className={classes.facilities}>Free  <span style={{whiteSpace: 'nowrap'}}>Wi-Fi</span>​</span>
                      <span className={classes.facilities}>Free Parking​</span>
                      <span className={classes.facilities}>Non Smoking</span>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Rooms));
