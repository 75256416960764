import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
// import PageLoading from '../components/Loading/PageLoading';

export const isAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state => state.auth.jwt !== null,
  authenticatingSelector: state => state.auth.isLoading,
  // AuthenticatingComponent: PageLoading,
  wrapperDisplayName: 'isAuthenticated',
  allowRedirectBack: true,
});

export const passwordCreated = connectedRouterRedirect({
  redirectPath: '/create-password',
  authenticatedSelector: state => state.auth.user.createPassword !== true,
  authenticatingSelector: state => state.auth.isLoading,
  // AuthenticatingComponent: PageLoading,
  wrapperDisplayName: 'passwordCreated',
  allowRedirectBack: true,
});

export const passwordNeedsCreating = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => state.auth.user.createPassword === true,
  authenticatingSelector: state => state.auth.isLoading,
  // AuthenticatingComponent: PageLoading,
  wrapperDisplayName: 'passwordNeedsCreating',
  allowRedirectBack: true,
});

export const isAdmin = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => state.auth.jwt !== null && state.auth.user.role === 'admin',
  authenticatingSelector: state => state.auth.isLoading,
  // AuthenticatingComponent: PageLoading,
  wrapperDisplayName: 'isAdmin',
  allowRedirectBack: false,
});

export const isNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: state => state.auth.jwt === null,
  authenticatingSelector: state => state.auth.isLoading,
  // AuthenticatingComponent: PageLoading,
  wrapperDisplayName: 'isNotAuthenticated',
  allowRedirectBack: false,
});
