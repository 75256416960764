const defaultState = { sidebar: window.innerWidth >= 768};

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };

    default:
      return state;
  }
};
