const defaultState = { from: '', to: '', rooms: []};

export const SET_BOOKING = 'SET_BOOKING';

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case SET_BOOKING:
      return { ...state, from: action.from, to: action.to, rooms: action.rooms };

    default:
      return state;
  }
};
