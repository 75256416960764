import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, TextField, Typography, CircularProgress, FormControlLabel, FormControl, FormHelperText, Checkbox } from '@mui/material';
import { withStyles } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../api';
import { resettingPassword, creatingPassword, checkToken, logUserOut } from '../actions/auth';
import Loading from '../components/Loading';

const MySwal = withReactContent(Swal)

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  }
});

class CreatePassword extends Component {

  state = {
    password: '',
    confirmpassword: '',
    terms: false,
    loading: false,
    error: false,
  }

  componentDidMount() {

  } 

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value, error: false });
  };

  submit = e => {
    e.preventDefault();
    if (this.state.error) return;

    if (!this.state.password || !this.state.confirmpassword) {
      this.setState({ error: 'Please confirm your password' });
      return;
    }

    if (this.state.password !== this.state.confirmpassword) {
      this.setState({ error: 'Passwords do not match' });
      return;
    }

    this.props.creatingPassword({
      password: this.state.password,
      terms: this.state.terms,
      token: this.props.auth.jwt
    }).then(() => {
      MySwal.fire({
        title: 'Success!',
        text: 'Your password has been created.',
        icon: 'success',
        showConfirmButton: true
      }).then(() => {
        this.props.checkToken({ token: this.props.auth.jwt })
      })
    }).catch(err => {
      console.log({err});
      this.setState({error: err.response.data.msg || err.response.statusText})
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        { this.state.loading && ( <Loading /> ) }
        <Typography component="h1" variant="h5">
          Create Password
        </Typography>
        <form noValidate className={classes.form} onSubmit={this.submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.onChange}
            value={this.state.password}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
            helperText={this.state.error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmpassword"
            label="Confirm password"
            type="password"
            id="confirmpassword"
            autoComplete="confirm-password"
            onChange={this.onChange}
            value={this.state.confirmpassword}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
            helperText={this.state.error}
          />
          <FormControl error={!!this.state.error}>
            <FormControlLabel
              control={<Checkbox name="terms" />}
              checked={this.state.terms}
              disabled={this.state.loading}
              onChange={(e) => this.onChange({ target: { name: 'terms', value: e.target.checked } })}
              label={<React.Fragment>I agree with the <a className={classes.link} href="/terms" target="_blank">terms and conditions</a> and the <a className={classes.link} href="/privacy" target="_blank">privacy policy</a></React.Fragment>}
            />
            {(!this.state.field || this.state.field === 'terms') && !!this.state.error && (<FormHelperText>{this.state.error}</FormHelperText>)}
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={this.props.auth.isLoading}
          >
            {this.props.auth.isLoading && <CircularProgress size={20} className={classes.circularProgress} />} Create password
          </Button>
        </form>
        <Button className={classes.link} onClick={this.props.logUserOut}>Sign out</Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, { resettingPassword, creatingPassword, checkToken, logUserOut })(withStyles(styles, { withTheme: true })(CreatePassword));