import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import ui from '../redux/ui';
import auth from '../redux/auth';
import booking from '../redux/booking';

const rootReducer = combineReducers({
  ui,
  auth,
  booking
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, promise)));

export default store;
