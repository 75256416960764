import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { isAuthenticated, passwordCreated } from '../config/authorization';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Menu } from '@material-ui/icons';
import { Drawer, Hidden } from '@mui/material';
import { withStyles } from '@mui/material/styles';
import logo from '../assets/purple-transform-logo-white-128x69.png'
import { logUserOut } from '../actions/auth';
// import socket from './config/sockets';
import DrawerContents from '../components/DrawerContents';
import Home from '../pages/Home';
import Rooms from '../pages/Rooms';
import BookRoom from '../pages/BookRoom';
import BookRoomForm from '../pages/BookRoomForm';
import Bookings from '../pages/Bookings';
import Working from '../pages/Working';
import AllBookings from '../pages/AllBookings';
import Users from '../pages/Users';
import About from '../pages/About';
// import Overview from './pages/Overview';
// import About from './pages/About';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: 240,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: 240,
  },
  appBar: {
    backgroundColor: '#582C83',
    [theme.breakpoints.up('md')]: {
      // width: `calc(100% - ${240}px)`,
      // marginLeft: 240,
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2, 0),
    maxWidth: `100%`,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 240px)',
      padding: theme.spacing(3),
    }
  },
  logo: {
    height: '3.5rem',
    margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        padding: '5px 48px 5px 0px',
      }
  }
});

class Dashboard extends Component {
  state = {
    mobileOpen: false,
  }

  handleDrawerToggle = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen
    })
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.handleDrawerToggle}  className={classes.menuButton}>
            <Menu />
          </IconButton>
          <img className={classes.logo} src={logo} />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
      <Drawer
            variant="temporary"
            anchor={'left'}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
        <Toolbar/>
            <DrawerContents handleDrawerToggle={this.handleDrawerToggle} user={this.props.auth.user} logOut={this.props.logUserOut} />
          </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Toolbar />
            <DrawerContents handleDrawerToggle={() => {}} user={this.props.auth.user} logOut={this.props.logUserOut} />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
      <Toolbar />
        <Switch>
          <Route component={Home} path="/" exact />
          <Route component={Rooms} path="/rooms" exact />
          <Route component={About} path="/about" exact />
          <Route component={BookRoom} path="/book" exact />
          <Route component={Bookings} path="/bookings" exact />
          <Route component={AllBookings} path="/allbookings" exact />
          <Route component={Users} path="/users" exact />
          <Route component={Working} path="/working" exact />
          <Route component={BookRoomForm} path="/book/form" exact />
        </Switch>
      </main>
    </div>
    );
  }
}

Dashboard.propTypes = {

};


const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default isAuthenticated(passwordCreated(connect(mapStateToProps, { logUserOut })(withStyles(styles, { withTheme: true })(Dashboard))));