import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { withStyles } from "@mui/material/styles";
import withWidth from '@mui/material/withWidth';
import { Button } from '@mui/material';
import moment from 'moment';
import IconPeople from '../assets/people.svg';
import rooms from '../config/rooms';

const styles = (theme) => ({
  container: {
    width: '100%',
    border: '1px solid rgba(88, 44, 131, 0.3)',
    // border: '1px solid #582C83',
    borderRadius: '5px',
    padding: '10px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'unset',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },

    "& .legendItem": {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: '3px 0px',
      justifyContent: 'center',
      [theme.breakpoints.up('lg')]: {
        width: '50%',
      },
      [theme.breakpoints.up('xl')]: {
        width: '25%',
      }
    },
    "& .legendKey": {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '5px',
      width: '50%',
      height: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    "& .legendLabel": {
      marginLeft: '10px',
      minWidth: '105px'
    },
    "& .onsite-working": {
      width: '100% !important',
    },
    "& .onsite-working .legendKey": {
      backgroundImage: `url(${IconPeople})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '22px',
      height: '22px',
      minWidth: '22px',
      opacity: 1,
      borderRadius: "23px",
      backgroundColor: '#582C83',
      border: '3px solid #582C83',
      margin: '2px',
    },
    "& .legendKey.room-red": {
      backgroundColor: `rgba(${rooms[0].color}, 0.5)`,
      borderLeft: `15px solid rgba(${rooms[0].color}, 0.8)`,
      borderRight: `15px solid rgba(${rooms[0].color}, 0.8)`,
    },
    "& .legendKey.room-green": {
      backgroundColor: `rgba(${rooms[1].color}, 0.5)`,
      borderLeft: `15px solid rgba(${rooms[1].color}, 0.8)`,
      borderRight: `15px solid rgba(${rooms[1].color}, 0.8)`,
    },
    "& .legendKey.room-blue": {
      backgroundColor: `rgba(${rooms[2].color}, 0.5)`,
      borderLeft: `15px solid rgba(${rooms[2].color}, 0.8)`,
      borderRight: `15px solid rgba(${rooms[2].color}, 0.8)`,
    },
    "& .legendKey.room-yellow": {
      backgroundColor: `rgba(${rooms[3].color}, 0.5)`,
      borderLeft: `15px solid rgba(${rooms[3].color}, 0.8)`,
      borderRight: `15px solid rgba(${rooms[3].color}, 0.8)`,
    },
  },
  checkButton: {
    height: '46px',
    marginTop: '10px',
    background: '#fff',
    borderRadius: '10px',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      marginTop: '0px',
      marginLeft: '10px',
      width: 'auto'
    },
  }
});

class CalLegend extends Component {
  state = {
    start: null,
    end: null,
    focusedInput: null
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>

        <div className='legendItem'>
          <div className='legendKey room-red'>
          </div>
          <div className='legendLabel'>
            {rooms[0].name}
          </div>
        </div>

        <div className='legendItem'>
          <div className='legendKey room-green'>
          </div>
          <div className='legendLabel'>
          {rooms[1].name}
          </div>
        </div>

        <div className='legendItem'>
          <div className='legendKey room-blue'>
          </div>
          <div className='legendLabel'>
          {rooms[2].name}
          </div>
        </div>

        <div className='legendItem'>
          <div className='legendKey room-yellow'>
          </div>
          <div className='legendLabel'>
          {rooms[3].name}
          </div>
        </div>

        <div className='legendItem onsite-working'>
          <div className='legendKey'>
          </div>
          <div className='legendLabel'>
          People will be working in the office
          {/* Indicates that people will be working in the offices */}
          </div>
        </div>
        
      </div>
    );
  }
}

export default withWidth()(withStyles(styles, { withTheme: true })(CalLegend))