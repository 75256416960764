import { SET_BOOKING } from "../redux/booking";

export const setNewBooking = (data) => ({
  type: SET_BOOKING,
  ...data
});

export const setBooking = ({ data }) => dispatch => {
    return dispatch(setNewBooking(data));
}
