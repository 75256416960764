import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/material/styles';
import { isAuthenticated, passwordNeedsCreating } from '../config/authorization';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import ForgottenPassword from '../pages/ForgottenPassword';
import bgimage from '../assets/oxford-house.jpg';
import logo from '../assets/purple-transform-logo.png'
import logoWhite from '../assets/purple-transform-logo-white.png'
import ResetPassword from '../pages/ResetPassword';
import CreatePassword from '../pages/CreatePassword';

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgimage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imagePaper: {
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${bgimage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
      '&::before': {
        content: '" "',
        display: 'block',
        width: '100%',
        height: '100%',
        // backgroundColor: 'rgba(88, 44, 131, 0.88)',
        backgroundColor: 'rgba(255, 255, 255, 0.88)',
        position: 'absolute'
      }
    },
  },
  circularProgress: {
    color: theme.palette.secondary.main,
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
  paper: {
    padding: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
  },
  logo: {
    width: '100%',
    maxWidth: '360px',
    margin: '2rem 0',
  }
});


class Authentication extends Component {
  render() {
    const {classes} = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className={classes.imagePaper} >
          <div className={classes.paper}>
          <img src={logo} className={classes.logo}/>
          <Switch>
            <Route path="/create-password" component={CreatePassword} />
          </Switch>
          </div>
        </Grid>
      </Grid>
    );
  }
}

Authentication.propTypes = {

};

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps)(isAuthenticated(passwordNeedsCreating(withStyles(styles, { withTheme: true })(Authentication))));