import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';

const CreateUserDialog = ({ open, cancel, confirm, firstname, lastname, email, password, onChange, loading, error }) => {
  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>Create new user</DialogTitle>
      <form onSubmit={confirm}>
        <DialogContent>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstname"
            label="First name"
            name="firstname"
            autoComplete="firstname"
            autoFocus
            onChange={onChange}
            value={firstname}
            disabled={loading}
            error={!!error}
            helperText={error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastname"
            label="Last name"
            name="lastname"
            autoComplete="lastname"
            onChange={onChange}
            value={lastname}
            disabled={loading}
            error={!!error}
            helperText={error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={onChange}
            value={email}
            disabled={loading}
            error={!!error}
            helperText={error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChange}
            value={password}
            disabled={loading}
            error={!!error}
            helperText={error}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancel}>Cancel</Button>
          <Button variant="contained" color="primary" type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateUserDialog;