import React, { Component } from 'react';
import PageTitle from "../components/PageTitle";
import Paper from "@mui/material/Paper";
import moment from 'moment';
import { connect } from 'react-redux';
import { withStyles } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CheckAvailability from '../components/CheckAvailability'
import api from '../api';
import rooms from '../config/rooms';
import { setNewBooking } from '../actions/booking';

import { Button, Checkbox, Typography } from '@mui/material';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 4),
    },
    position: "relative",
  },
  roomContainer: {
    width: '100%',
    display: 'flex',
    border: '1px solid #bbb',
    padding: theme.spacing(2),
    borderRadius: '5px',
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
  },
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.8)',
    top: 0,
    left: 0,
    display: 'flex',
    color: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2.5rem',
    fontWeight: '200',
    cursor: 'default'
  },
  selected: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(88,44,130,0.1)',
    top: 0,
    left: 0,
  },
  image: {
    width: '100px',
    borderRadius: '10px',
    margin: theme.spacing(0, 2, 0, 0)
  },
  infoArea: {
    flex: 1
  },
  header: {
    margin: 0,
  },
  info: {
    display: 'block'
  },
  checkboxArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class BookRoom extends Component {
  state = {
    urlStart: '',
    urlEnd: '',
    start: '',
    end: '',
    checkedPreDates: false,
    availability: [],
    selected: [],
    working: null,
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const start = queryParams.get('start');
    const end = queryParams.get('end');
    const working = queryParams.get('working');

    this.setState({ urlStart: start, urlEnd: end, checkedPreDates: true, working });

    if (start && end) {
      this.submitChecker({ start: moment(start), end: moment(end) });
    }

  }

  submitChecker = async ({ start, end }) => {
    const token = this.props.auth.jwt;
    const response = await api.rooms.checkAvailability({ from: start, to: end, token });
    this.setState({ availability: [...response.data.availableRooms], start, end })
  }

  selectCheckbox = (room, e) => {
    if (!this.state.availability.includes(room)) return;
    if (e && e.target.type === 'checkbox') return;
    const selection = [...this.state.selected];
    const index = selection.findIndex(e => e === room);
    if (index !== -1) {
      // remove from selection
      selection.splice(index, 1);
    } else {
      // add to selection
      selection.push(room);
    }

    this.setState({ selected: [...selection] });
  }

  goToConfirmBooking = () => {
    const data = {
      from: this.state.start,
      to: this.state.end,
      rooms: [...this.state.selected]
    }

    const booking = this.props.setNewBooking(data);

    this.props.history.push(`/book/form${this.state.working ? '?working=true' : ''}`)
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <PageTitle>Book Room</PageTitle>
        <Paper elevation={0} square className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {this.state.checkedPreDates && (
                  <CheckAvailability start={this.state.urlStart} end={this.state.urlEnd} onSubmit={this.submitChecker} />
              )}
            </Grid>
            {(!this.state.start || !this.state.end) && (
              <Grid item xs={12}>
                <Typography variant="body1">Please select your desired checkin and checkout dates</Typography>
              </Grid>
            )}
            {(this.state.start && this.state.end && !this.state.availability.length) && (
              <Grid item xs={12}>
                <Typography variant="body1">No rooms are available for your selected dates</Typography>
              </Grid>
            )}
            {(this.state.start && this.state.end && !!this.state.availability.length) && (
              <Grid item xs={12}>
                <Typography variant="body1">Rooms available for: {this.state.start.format("ddd MMM DD, YYYY")} - {this.state.end.format("ddd MMM DD, YYYY")}</Typography>
              </Grid>
            )}
            {rooms.map(room => (
              <Grid item xs={12} key={room.id}>
                <div 
                className={classes.roomContainer} 
                onClick={(e) => this.selectCheckbox(room.id, e)} 
                // style={ !this.state.availability.includes(room.id) ? {opacity: '0.4'} : {opacity: '1'} }
                  >
                  {!this.state.availability.includes(room.id) && (
                    <div className={classes.overlay} ></div>
                  )}
                  {this.state.selected.includes(room.id) && this.state.availability.includes(room.id) && (
                    <div className={classes.selected} />
                  )}
                  <img className={classes.image} src={room.image} alt={room.name} />
                  <div className={classes.infoArea}>
                    <h2 className={classes.header} >{room.name}</h2>
                    <span className={classes.info}>Sleeps: {room.sleeps}</span>
                    {/* <span className={classes.info}>Ensuite: {room.ensuite ? 'Yes' : 'No'}</span> */}
                    <span className={classes.info}>Bathroom: {room.bathrooms}</span>
                  </div>
                  {this.state.start && this.state.end && (
                    <div className={classes.checkboxArea}>
                      <Checkbox disabled={!this.state.availability.includes(room.id)} name={room.id} onChange={(e) => this.selectCheckbox(room.id)} checked={this.state.selected.includes(room.id) && this.state.availability.includes(room.id)}/>
                    </div>
                  )}
                </div>
              </Grid>
            ))}
            <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={this.goToConfirmBooking} disabled={!this.state.selected.length}>Next</Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, { setNewBooking })(withStyles(styles, { withTheme: true })(BookRoom));
