import React from 'react';
import { NavLink } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import WorkIcon from '@material-ui/icons/Work';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HotelIcon from '@material-ui/icons/Hotel';
import ListIcon from '@material-ui/icons/List';
import BookIcon from '@material-ui/icons/Book';
import InfoIcon from '@material-ui/icons/Info';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Divider from '@mui/material/Divider';
import GroupIcon from '@material-ui/icons/Group';
import RoomServiceIcon from '@material-ui/icons/RoomService';

const DrawerContents = ({ handleDrawerToggle, logOut, user }) => {
  return (
    <div>
      <List>
        <ListItem component={NavLink} to="/" exact activeClassName="Mui-selected" onClick={handleDrawerToggle} button>
          <ListItemIcon><DateRangeIcon /></ListItemIcon>
          <ListItemText primary="Availability" />
        </ListItem>
        <ListItem component={NavLink} to="/book" exact activeClassName="Mui-selected" onClick={handleDrawerToggle} button>
          <ListItemIcon><HotelIcon /></ListItemIcon>
          <ListItemText primary="Book Room" />
        </ListItem>
        <ListItem component={NavLink} to="/bookings" exact activeClassName="Mui-selected" onClick={handleDrawerToggle} button>
          <ListItemIcon><BookIcon /></ListItemIcon>
          <ListItemText primary="My Bookings" />
        </ListItem>
        <ListItem component={NavLink} to="/working" exact activeClassName="Mui-selected" onClick={handleDrawerToggle} button>
          <ListItemIcon><WorkIcon /></ListItemIcon>
          <ListItemText primary="My Work Days" />
        </ListItem>
        <ListItem component={NavLink} to="/rooms" exact activeClassName="Mui-selected" onClick={handleDrawerToggle} button>
          <ListItemIcon><ListIcon /></ListItemIcon>
          <ListItemText primary="Room Details" />
        </ListItem>
        <ListItem component={NavLink} to="/about" exact activeClassName="Mui-selected" onClick={handleDrawerToggle} button>
          <ListItemIcon><InfoIcon /></ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>
        {user.role === 'admin' && (
        <React.Fragment>
          <ListItem component={NavLink} to="/allbookings" exact activeClassName="Mui-selected" onClick={handleDrawerToggle} button>
            <ListItemIcon><RoomServiceIcon /></ListItemIcon>
            <ListItemText primary="All Bookings" />
          </ListItem>
          <ListItem component={NavLink} to="/users" exact activeClassName="Mui-selected" onClick={handleDrawerToggle} button>
            <ListItemIcon><GroupIcon /></ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </React.Fragment>
        )}
        <Divider style={{ margin: '10px 0' }}/>
        <ListItem onClick={logOut} button>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
};

export default DrawerContents;