import { LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, FORGOTTEN_PASSWORD_SUBMIT, RESET_PASSWORD_SUBMIT, CREATE_PASSWORD_SUBMIT, CREATE_PASSWORD_FAIL } from "../redux/auth";
import api from '../api';

export const pending = () => ({
  type: LOGIN_PENDING,
});

export const loggedIn = (jwt, user) => ({
  type: LOGIN_SUCCESS,
  jwt,
  user
});

export const loginFailed = () => ({
  type: LOGIN_FAILURE,
});

export const forgottenPasswordSubmitted = () => ({
  type: FORGOTTEN_PASSWORD_SUBMIT,
});

export const passwordReset = () => ({
  type: RESET_PASSWORD_SUBMIT,
});

export const passwordCreate = () => ({
  type: CREATE_PASSWORD_SUBMIT,
});

export const createFailed = () => ({
  type: CREATE_PASSWORD_FAIL,
});

export const loggedOut  = () => ({
  type: LOGOUT,
});

export const checkToken = ({ token }) => dispatch => {
  dispatch(pending());
  return api.auth.user({ token }).then(res => {
    dispatch(loggedIn(token, res.data.user));
  }).catch(err => {
    localStorage.removeItem('jwt');
    dispatch(loginFailed());
  });
}

export const logUserIn = ({ email, password }) => dispatch => {
  dispatch(pending());
  return api.auth.login({ email, password }).then(auth => {
    localStorage.setItem('jwt', auth.data.token);
    dispatch(loggedIn(auth.data.token, auth.data.user));
  }).catch(err => {
    dispatch(loginFailed());
    throw err;
  });
}

export const signUserUp = ({ firstname, lastname, email, password, confirmpassword, terms }) => dispatch => {
  dispatch(pending());
  return api.auth.signup({ firstname, lastname, email, password, confirmpassword, terms }).then(auth => {
    localStorage.setItem('jwt', auth.data.token);
    dispatch(loggedIn(auth.data.token, auth.data.user));
  }).catch(err => {
    dispatch(loginFailed());
    throw err;
  });
}

export const forgottenPassword = ({ email }) => dispatch => {
  dispatch(pending());
  return api.auth.forgottenPassword({ email }).then(() => {
    dispatch(forgottenPasswordSubmitted());
  }).catch(err => {
    dispatch(loginFailed());
    throw err;
  });
}

export const resettingPassword = ({ code, password }) => dispatch => {
  dispatch(pending());
  return api.auth.resetPassword({ code, password }).then(() => {
    dispatch(passwordReset());
  }).catch(err => {
    dispatch(loginFailed());
    throw err;
  });
}

export const creatingPassword = ({ password, terms, token }) => dispatch => {
  dispatch(pending());
  return api.auth.createPassword({ password, terms, token }).then(() => {
    dispatch(passwordCreate());
  }).catch(err => {
    dispatch(createFailed());
    throw err;
  });
}

export const logUserOut = () => dispatch => {
  localStorage.removeItem('jwt');
  dispatch(loggedOut());
}