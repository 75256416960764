import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { withStyles } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import api from '../api';
import { resettingPassword } from '../actions/auth';
import Loading from '../components/Loading';

const MySwal = withReactContent(Swal)

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  }
});

class ResetPassword extends Component {

  state = {
    code: '',
    password: '',
    confirmpassword: '',
    loading: false,
    error: false,
  }

  componentDidMount() {
    const qs = new URLSearchParams(this.props.location.search);
    const code = qs.get("code");
    if (!code) {
      this.setState({ error: 'Invalid reset password link' });
      return;
    }

    this.setState({ code });
    this.checkResetCode(code);
  }

  checkResetCode = async (code) => {
    this.setState({ loading: true });
    const check = await api.auth.checkResetCode({ code }).catch(err => {
      // this.setState({ error: err.response.data.errors.global || err.response.statusText });
      const errMessage = err.response?.data?.msg || "Something went wrong";
      this.setState({ error: errMessage });
    });

    this.setState({ loading: false });
  }  

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value, error: false });
  };

  submit = e => {
    e.preventDefault();
    if (this.state.error) return;

    if (!this.state.password || !this.state.confirmpassword) {
      this.setState({ error: 'Please confirm your password' });
      return;
    }

    if (this.state.password !== this.state.confirmpassword) {
      this.setState({ error: 'Passwords do not match' });
      return;
    }

    this.props.resettingPassword({
      code: this.state.code,
      password: this.state.password,
    }).then(() => {
      MySwal.fire({
        title: 'Success!',
        text: 'Your password has been reset. Please login to continue.',
        icon: 'success',
        showConfirmButton: true
      }).then(() => {
        this.props.history.push('/login');
      })
    }).catch(err => {
      console.log({err});
      this.setState({error: err.response.data.msg || err.response.statusText})
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <React.Fragment>
        { this.state.loading && ( <Loading /> ) }
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form noValidate className={classes.form} onSubmit={this.submit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.onChange}
            value={this.state.password}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
            helperText={this.state.error}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmpassword"
            label="Confirm password"
            type="password"
            id="confirmpassword"
            autoComplete="confirm-password"
            onChange={this.onChange}
            value={this.state.confirmpassword}
            disabled={this.props.auth.isLoading}
            error={!!this.state.error}
            helperText={this.state.error}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={this.props.auth.isLoading}
          >
            {this.props.auth.isLoading && <CircularProgress size={20} className={classes.circularProgress} />} Reset password
          </Button>
        </form>
        <Link className={classes.link} to="/login">Login to your account</Link>
        {/* <Link className={classes.link} to="/signup">Create new account</Link> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, { resettingPassword })(withStyles(styles, { withTheme: true })(ResetPassword));