const defaultState = { jwt: null, user: null, isLoading: false };

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const FORGOTTEN_PASSWORD_SUBMIT = 'FORGOTTEN_PASSWORD_SUBMIT';
export const RESET_PASSWORD_SUBMIT = 'RESET_PASSWORD_SUBMIT';
export const CREATE_PASSWORD_SUBMIT = 'CREATE_PASSWORD_SUBMIT';
export const CREATE_PASSWORD_FAIL = 'CREATE_PASSWORD_FAIL';
export const LOGOUT = 'LOGOUT';

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, jwt: action.jwt, user: action.user, isLoading: false };

    case LOGIN_PENDING:
      return { ...state, isLoading: true };

    case LOGIN_FAILURE:
      return { ...state, jwt: null, user: null, isLoading: false };

    case FORGOTTEN_PASSWORD_SUBMIT:
      return { ...state, jwt: null, user: null, isLoading: false };

    case RESET_PASSWORD_SUBMIT:
      return { ...state, jwt: null, user: null, isLoading: false };

    case CREATE_PASSWORD_SUBMIT:
      return { ...state, isLoading: false };

    case CREATE_PASSWORD_FAIL:
      return { ...state, isLoading: false };

    case LOGOUT:
      return { ...state, jwt: null, user: null, isLoading: false };

    default:
      return state;
  }
};
