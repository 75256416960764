import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.swal2-container': {
          zIndex: 1300
        },
        '.DateRangePicker_picker': {
            zIndex: '1300',
            backgroundColor: 'rgba(0,0,0,.8)'
        },
        '.SingleDatePicker_picker': {
          zIndex: '1300',
          backgroundColor: 'rgba(0,0,0,.8)'
      }
      }
    },
    Link: {
      color: 'red'
    }
  },
  palette: {
    primary: {
      main: '#582C83',
        action: {
          hover: '#3b1d56',
      },
    },
    secondary: {
      main: '#68bb97',
    }
  },
});

export default theme;