import room1Main from '../assets/rooms/mh-bedroom-master-01.jpg';
import room1Bathroom from '../assets/rooms/mh-bedroom-master-ensuite-01.jpg';
import room2Main from '../assets/rooms/mh-bedroom-2nd-01.jpg';
import room2Bathroom from '../assets/rooms/mh-bedroom-2nd-shower-room-01.jpg';
import room3Main from '../assets/rooms/mh-bedroom-3rd-01.jpg';
import room3Second from '../assets/rooms/mh-bedroom-3rd-02.jpg';
import room3Bathroom from '../assets/rooms/mh-bedroom-3rd-4th-family-bathroom-01.jpg';
import room4Main from '../assets/rooms/mh-bedroom-4th-01.jpg';
import room4Bathroom from '../assets/rooms/mh-bedroom-3rd-4th-family-bathroom-01.jpg';

const rooms = [
  {
    id: 'room-1',
    class: 'event-red',
    color: '158, 42, 43',
    name: 'SA&BRE',
    sleeps: 2,
    facilities: 'Super King-size bed with Dressing room',
    ensuite: true,
    bathrooms: 'En-suite',
    image: room1Main,
    gallery: [
      room1Main,
      room1Bathroom
    ]
  },
  {
    id: 'room-2',
    class: 'event-green',
    color: '107, 170, 117',
    name: 'ODIiN',
    sleeps: 2,
    facilities: 'Kingsize Bed with bathroom directly opposite',
    ensuite: false,
    bathrooms: 'Private',
    image: room2Main,
    gallery: [
      room2Main,
      room2Bathroom
    ]
  },
  {
    id: 'room-3',
    class: 'event-blue',
    color: '0, 71, 119',
    name: 'HORUiS',
    sleeps: 3,
    facilities: 'Kingsize Bed and additional day bed and shares a bathroom with JANUiS',
    ensuite: false,
    bathrooms: 'Shared',
    image: room3Main,
    gallery: [
      room3Main,
      room3Second,
      room3Bathroom
    ]
  },
  {
    id: 'room-4',
    class: 'event-yellow',
    color: '244, 201, 93',
    name: 'JANUiS',
    sleeps: 2,
    facilities: 'Double Bed and shares a bathroom with HORUiS',
    ensuite: false,
    bathrooms: 'Shared',
    image: room4Main,
    gallery: [
      room4Main,
      room4Bathroom
    ]
  }
];

export default rooms;