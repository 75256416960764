import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { withStyles } from "@mui/material/styles";
import withWidth from '@mui/material/withWidth';
import { Button } from '@mui/material';
import moment from 'moment';

const styles = (theme) => ({
  container: {
    border: '1px solid rgba(88, 44, 131, 0.3)',
    borderRadius: '5px',
    height: '100%',
  },
  innerContainer: {
    width: '100%',
    borderRadius: '5px',
    padding: '10px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    },

    "& .DateRangePicker": {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        flex: 1,
      },
    },

    "& .DateRangePickerInput.DateRangePickerInput__withBorder": {
      borderRadius: '10px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly'
    },

    "& .DateInput_input.DateInput_input_1": {
      borderRadius: '10px',
    },

    "& .DateRangePickerInput .DateInput": {
      background: 'transparent'
    },
  },
  checkButton: {
    height: '46px',
    marginTop: '10px',
    // background: '#582C83',
    background: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '10px',
    width: '100%',
    lineHeight: '1',
    [theme.breakpoints.up('lg')]: {
      marginTop: '0px',
      marginLeft: '10px',
      width: 'auto'
    },
    "&:hover": {
      background: theme.palette.primary.action.hover,
      },
      "&.Mui-disabled": {
      background: '#c3c3c3',
      }
  },
  title: {
    display: 'block',
    padding: '5px 10px 5px 10px',
    fontWeight: 500,
    fontSize: '1.3rem',
    // textAlign: 'center',
    color: '#000000',
    borderBottom: '1px solid rgba(88, 44, 131, 0.3)',
  }
});

class CheckAvailability extends Component {
  state = {
    start: null,
    end: null,
    focusedInput: null
  }

  componentDidMount() {
    if (this.props.start) {
      const startDay = moment(this.props.start);
      const endDay = this.props.end ? moment(this.props.end) : null;
      const focusedInput = this.props.end ? null : "endDate";

      this.setState({
        start: startDay,
        end: endDay,
        focusedInput
      })
    }
  }
  

  onSubmit = () => {
    if (!this.state.start || !this.state.end) return;
    this.props.onSubmit({ start: this.state.start, end: this.state.end });
  }

  onDatesChange = ({ start, end }) => {
    this.setState({ start, end }, () => {
      if (start && end) {
        this.onSubmit()
      }
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.title}>Start your booking</div>
        <div className={classes.innerContainer}>
          <DateRangePicker
            startDate={this.state.start} // momentPropTypes.momentObj or null,
            startDateId="check_in_date" // PropTypes.string.isRequired,
            endDate={this.state.end} // momentPropTypes.momentObj or null,
            endDateId="check_out_date" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => this.onDatesChange({ start: startDate, end: endDate })} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            startDatePlaceholderText="Check in"
            endDatePlaceholderText="Check out"
            showClearDates={true}
            numberOfMonths={ this.props.width == 'sm' ||  this.props.width == 'xs' ? 1 : 2}
            withPortal={true}
            displayFormat="DD/MM/YYYY" 
          />
          <Button onClick={this.onSubmit} className={classes.checkButton} variant="outlined" disabled={!this.state.start || !this.state.end}>Submit</Button>
        </div>
      </div>
    );
  }
}

export default withWidth()( withStyles(styles, { withTheme: true })(CheckAvailability))